import React from "react";

const ArticleEditor = () => {
    const [text,setText] = React.useState('');

    return (
        <div className="App">
        </div>
    )
}

export default ArticleEditor;