import React from "react";
import { FormSelect } from "shards-react";


const CustomFormSelect = ({batch, selectedBatchId, setSelectedBatchId }) => {

    const SelectCategory = (name, setSelectedBatchId) => {
        const catInfo = batch.find((cat) => cat.name === name);
        if (catInfo && catInfo._id) {
            setSelectedBatchId(catInfo._id);
        } else {
            setSelectedBatchId(null)
        }
    }

    return (
        <FormSelect onChange={(event) => SelectCategory(event.target.value, setSelectedBatchId)} id="feInputState">
            <option>All</option>
            {
                batch && batch.length && batch.map((cat, i) => (
                    <option selected={selectedBatchId === cat._id} key={i} id={cat._id}>{cat.name}</option>
                ))
            }
        </FormSelect>
    )
}

export default CustomFormSelect;