import React, { useState } from 'react';
import {
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Container,
    Collapse,
    NavbarToggler
} from 'shards-react';
import { StyleSheet, css } from 'aphrodite';

const styles = StyleSheet.create({
    navbar: {
        position: "sticky",
        top: 0,
        zIndex: 1,
        background: "#fcfcfc",
        // background: 'rgb(238,174,202)',
        // background: 'radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(195,180,217,1) 48%, rgba(148,187,233,1) 100%)',
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
        padding: '0.5rem 0',
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    navbarBrand: {
        fontWeight: 'bold',
        fontSize: '1.5rem',
        letterSpacing: '-1px',
        textTransform: 'uppercase',
        padding: '0.5rem 1rem',
        borderRadius: '4px',
        transition: 'all 0.3s ease',
        background: "#349beb",
        // background: 'rgb(131,58,180)',
        // background: 'linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 46%, rgba(252,176,69,1) 100%)',
        backgroundClip: 'text',
        WebkitBackgroundClip: 'text',
        color: 'transparent',
        textShadow: '2px 2px 4px rgba(0,0,0,0.1)',
        ':hover': {
            transform: 'scale(1.05)',
        }
    },
    nav: {
        display: 'flex',
        alignItems: 'center',
    },
    navLink: {
        color: '#000408',
        fontWeight: '500',
        position: 'relative',
        overflow: 'hidden',
        fontWeight: "bold",
        transition: 'all 0.3s ease',
        ':before': {
            content: '""',
            position: 'absolute',
            top: '100%',
            left: '0',
            width: '100%',
            height: '3px',
            transition: 'all 0.3s ease',
        },
        ':hover': {
            color: '#3498db',
            ':before': {
                top: 'calc(100% - 3px)',
            }
        }
    },
    activeNavLink: {
        padding: "10px",
        color: '#3498db',
        backgroundColor: '#fcfcfc',
        ':before': {
            top: 'calc(100% - 3px)',
        }
    },
    enrollButton: {
        backgroundColor: '#e74c3c',
        color: '#ffffff',
        fontWeight: 'bold',
        padding: '0.7rem 1.5rem',
        borderRadius: '25px',
        transition: 'all 0.3s ease',
        ':hover': {
            backgroundColor: '#c0392b',
            transform: 'translateY(-2px)',
            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
        }
    },
    enrollButton2: {
        backgroundColor: '#e74c3c',
        color: '#ffffff',
        fontWeight: 'bold',
        // borderRadius: '25px',
        transition: 'all 0.3s ease',
        ':hover': {
            backgroundColor: '#c0392b',
            transform: 'translateY(-2px)',
            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
        }
    }
});

const EnhancedNavbar = ({ activeLink, handleNavClick, isAuthenticated }) => {
    const [collapseOpen, setCollapseOpen] = useState(false);

    const toggleNavbar = () => {
        setCollapseOpen(prevState => !prevState);
    };

    return (
        <Navbar expand="md" className={css(styles.navbar)} >
            <Container className={css(styles.container)}>
                <NavbarBrand href="#" className={css(styles.navbarBrand)} onClick={(e) => handleNavClick('home', e)}>
                    <img src="https://cbcclasses.com/logo_new.png" alt="CBC Classes" width={50} height={50} />
                    CBC Classes
                </NavbarBrand>

                <NavbarToggler className="p-0 m-0 rounded" style={{backgroundColor: '#e74c3c',}}>
                    <NavLink href={!isAuthenticated || isAuthenticated === "false" ? "/login" : "/batch"} className={css(styles.enrollButton2)}>
                        {!isAuthenticated || isAuthenticated === "false" ? "Login" : "Dashboard"}
                    </NavLink>
                </NavbarToggler>

                <Collapse open={collapseOpen} navbar className="justify-content-end">
                    <Nav navbar className={css(styles.nav)}>
                        {['Home', 'About', 'Subjects', 'Testimonials', 'Contact'].map((link) => (
                            <NavItem key={link.toLowerCase()}>
                                <NavLink
                                    href={`#${link.toLowerCase()}`}
                                    onClick={(e) => handleNavClick(link.toLowerCase(), e)}
                                    className={css(
                                        styles.navLink,
                                        activeLink === link.toLowerCase() && styles.activeNavLink
                                    )}
                                >
                                    {link}
                                </NavLink>
                            </NavItem>
                        ))}
                        <NavItem>
                            <NavLink href={!isAuthenticated || isAuthenticated === "false" ? "/login" : "/batch"} className={css(styles.enrollButton)}>
                                {!isAuthenticated || isAuthenticated === "false" ? "Teacher login" : "Teacher Dashboard"}
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Container>
        </Navbar>
    );
};

export default EnhancedNavbar;
