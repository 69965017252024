import React from "react";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";

import routes from "./routes";
import withTracker from "./withTracker";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styling/styles/shards-dashboards.1.1.0.min.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import SignIn from "./views/SingIn";
import { getFromLocalStorage } from "./lib/helper";
import { Constants } from "./flux";
import "./App.css";
import HomePage from "./views/Home";

export default () => {

  const isAuthenticated = getFromLocalStorage(Constants.IS_AUTHENTICATED);

  return (
    <Router basename={process.env.REACT_APP_BASENAME || ""}>
      <div>
        <ToastContainer newestOnTop={true} autoClose={1000} position="top-right" hideProgressBar={true} />

        <Switch>
          {/* Always accessible HomePage route */}
          <Route exact path="/home" component={withTracker(HomePage)} />
          
          {/* Redirect root to HomePage */}
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>

          {/* Login route */}
          {!isAuthenticated || isAuthenticated === "false" ? (
            <Route path="/login" component={withTracker(SignIn)} />
          ) : (
            <Redirect from="/login" to="/home" />
          )}

          {/* Authenticated routes */}
          {isAuthenticated && routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={withTracker(props => (
                <route.layout {...props}>
                  <route.component {...props} />
                </route.layout>
              ))}
            />
          ))}
        </Switch>
      </div>
    </Router>
  );
};
