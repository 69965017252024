import React, { useState } from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, Button, ButtonGroup } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { toast } from "react-toastify";
import Loader from "../components/loader";
import PopUpImage from "../components/popupModal/imageModal";
import ArticleEditor from "../components/feeds/ArticleEditor";
import PublishDraftGroup from "../components/common/PublishedDraft";

const Feeds = () => {
    // ... (existing state variables)

    const [selectedTab, setSelectedTab] = useState('articles');
    const [articleContent, setArticleContent] = useState('');
    const [articleTitle, setArticleTitle] = useState('');
    const [showArticlePreview, setShowArticlePreview] = useState(false);
    const [loader, setLoader] = useState(false);
    const [status, setStatus] = React.useState(1);

    // ... (existing useEffect hooks and functions)

    const handleArticleSubmit = () => {
        // Here you would typically send the article data to your backend
        console.log('Article submitted:', { title: articleTitle, content: articleContent });
        toast.success('Article submitted successfully!');
        setArticleTitle('');
        setArticleContent('');
    };

    const renderContent = () => {
        switch (selectedTab) {
            case 'articles':
                return renderStudentsContent();
            case 'add_article':
                return renderArticlesContent();
            default:
                return renderStudentsContent();
        }
    };

    const renderStudentsContent = () => {
        // ... (existing students table rendering logic)
    };

    const renderArticlesContent = () => {
        return (
                    <ArticleEditor
                        content={articleContent}
                        setArticleContent={setArticleContent}
                    />
            // <Card className="m-0 p-0">
            //     <CardBody className="mb-2">
            //     </CardBody>
            //     <PublishDraftGroup published="Publish" draft="Draft" status={status} onClick={setStatus} />
            //     <Button theme="outline-primary" onClick={handleArticleSubmit}>Save</Button>
            // </Card>

        );
    };

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-2">
                <PageTitle sm="4" name="CBC Classes" subtitle="" className="text-sm-left" />
            </Row>

            <Row>
                <Col>
                    <Card small className="mb-4">
                        <CardHeader className="border-bottom">
                            <ButtonGroup>
                                <Button onClick={() => setSelectedTab('articles')} className={`mr-2 pr-4 ${selectedTab === 'articles' ? 'btn-primary' : 'btn-outline-primary'}`}>Articles</Button>
                                <Button onClick={() => setSelectedTab('add_article')} className={`ml-2 pr-4 ${selectedTab === 'add_article' ? 'btn-primary' : 'btn-outline-primary'}`}>Add Article</Button>
                            </ButtonGroup>

                        </CardHeader>
                        <CardBody>
                            {renderContent()}
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            {showArticlePreview && (
                <PopUpImage
                    showModal={showArticlePreview}
                    setShowModal={setShowArticlePreview}
                    content={
                        <div>
                            <h2>{articleTitle}</h2>
                            <div dangerouslySetInnerHTML={{ __html: articleContent }} />
                        </div>
                    }
                />
            )}

            <Loader start={loader} />
        </Container>
    );
};

export default Feeds;
