import React, { useState } from "react";
import { toast } from "react-toastify";
import { Constants } from "../flux";
import { saveInLocalStorage } from "../lib/helper";
import { loginWithPassword } from "../lib/request";
import "../styling/styles/newsignin.css";
import { Card, CardBody, CardTitle, Col, Container, Form, FormGroup, FormInput, Row, Button } from "shards-react";

const SignIn = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLogin = async (event) => {
    event.preventDefault();

    if (!username || !password) {
      toast.error("Email and password are required!");
      return;
    }

    setLoading(true);

    try {
      const response = await loginWithPassword("/auth", {
        user: { email: username, password },
      });

      const tokenInfo = response && response.data;

      if (tokenInfo && tokenInfo.role === "admin") {
        saveInLocalStorage("role", "Admin");
        saveInLocalStorage(Constants.IS_AUTHENTICATED, "true");
        saveInLocalStorage("token", tokenInfo.token);
        window.location.reload();
      } else {
        toast.error("Invalid credentials. Please try again.");
      }
    } catch (error) {
      console.error("Login error:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="mt-5 col-md-3 col-md-6">
      <Row>
        <Col>
          <h2 className="text-center mb-4 font-weight-bold">Welcome Back</h2>
          <Card>
            <CardBody className="d-flex flex-column">
              <CardTitle className="text-center font-weight-bold">
                <img src="https://cbcclasses.com/logo_new.png" alt="CBC Commerce Classes" width={30} height={30} className="mr-1" />
                <a href="/home">CBC Commerce Classes </a>
              </CardTitle>
              <Form onSubmit={handleLogin}>
                <FormGroup>
                  <label htmlFor="username " className="font-weight-bold">Email</label>
                  <FormInput
                    size="sm"
                    id="username"
                    placeholder="Please Enter Your Email"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <label htmlFor="password" className="font-weight-bold">Password</label>
                  <FormInput
                    size="sm"
                    type="password"
                    id="password"
                    placeholder="Please Enter Your Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </FormGroup>
              </Form>
              <Button onClick={(e) => { handleLogin(e) }} size="sm" outline theme="primary" className="mb-2">
                {loading ? "Logging in..." : "Login"}
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SignIn;
