import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, Button, ButtonGroup, FormInput } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import { GET_REQUEST, POST_REQUEST } from "../lib/request";
// import ActionButton from "../components/ActionButton";
import { toast } from "react-toastify";
import Loader from "../components/loader";
// import { Constants } from "../flux";
// import CreateStudents from "../components/students/CreateStudents";
// import PublishDraftGroup from "../components/common/PublishedDraft";
import Pagination from "../components/index";
import PopUpImage from "../components/popupModal/imageModal";
import PaymentModel from "../components/popupModal/paymentModal";
import ViewPaymentModal from "../components/popupModal/ViewPaymentModal";
// import CustomFormSelect from "../components/common/Select";

const FeeOversight = () => {

    const [selected, setSelected] = React.useState(0)
    const [students, setStudents] = React.useState([]);
    const [fee, setFee] = React.useState(0);
    const [note, setNote] = React.useState("");
    const [loader, setLoader] = React.useState(0);
    const [selectedStudent, setSelectedStudent] = React.useState({});
    const [skip, setSkip] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [totalCount, setTotalResults] = React.useState(0);
    const [selectedImage, setSelectedImage] = React.useState("");
    const [showModal, setShowModal] = React.useState(false);
    const [paymentModal, setPaymentModal] = React.useState(false);
    const [clearDue, setClearDue] = React.useState(false);
    const [payment, setPayment] = React.useState(0);
    const [debouncedValue, setDebouncedValue] = React.useState("");
    const [searchText, setSearchText] = React.useState("");
    const [viewPaymentModal, setViewPaymentModal] = React.useState(false);


    const GET_PAYMENTS = (searchText = "") => {
        setLoader(1)
        GET_REQUEST(`/payment?limit=100&skip=${skip}&search=${searchText}`)
            .then((response) => {
                // console.log('response.data', response.data);
                if (response && response.data && response.data.payments) {
                    setStudents(response.data.payments);
                    setTotalResults(response.data.totalResults);
                }
                if (response.status === 204) {
                    setStudents([]);
                    setTotalResults(0)
                }
                setLoader(0)
            })
            .catch((error) => {
                toast.error("Failed to Fetch Batches")
                console.log('Error in fetching data', error);
                setLoader(0)
            })
    }

    React.useEffect(() => {
        GET_PAYMENTS();
    }, [showModal])


    const PaginationHandler = (data) => {
        setSkip((data - 1) * 10);
        setPage(data);
        // const skip = (data - 1) * 10
    }

    const onImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
        setShowModal(true);
    }

    const AddPayment = (studentId) => {
        setLoader(1);
        let dataToSave = {
            studentId,
            receivedPayment: Number(payment),
            payment: clearDue ? selectedStudent.dues : Number(payment),
            note: note || "",
            timestamp: new Date().getTime(),
            paidOn: new Date()
        }
        console.log(dataToSave);
        setPaymentModal(false);
        // toast.success("Payment Added Successfully!");
        POST_REQUEST("/payment", { payment: dataToSave, studentId }).then((response) => {
            if (response.status === 201) {
                toast.success("Payment Added Successfully!");
                GET_PAYMENTS()
            }
        }).catch((error) => {
            console.error('Error Received', error);
            toast.success("Error Adding Payment. Try After Some Time!");
            setLoader(0);
        })
        // setTimeout(() => {
        //     setLoader(0)
        // }, 2000)
    }

    const openPaymenModal = (studentId, viewPaymentModal = false) => {
        const studentInfo = students.find((obj) => obj._id === studentId);
        setSelectedStudent(studentInfo);
        setPayment(studentInfo.dues);
        if (viewPaymentModal) {
            return setViewPaymentModal(true);
        }
        return setPaymentModal(true);
    }

    React.useEffect(() => {
        console.log('stopped typing....', debouncedValue);
        GET_PAYMENTS(debouncedValue);
    }, [debouncedValue])


    React.useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(searchText);
        }, 700);

        return () => {
            clearTimeout(handler);
        };
    }, [searchText]);


    if (selected) {
        return (
            <Container fluid className="main-content-container px-4 pb-4">
                <Loader start={loader} />
                {/* Page Header */}
                <Row noGutters className="page-header py-2">
                    <PageTitle sm="4" name="CBC Classes" subtitle="" className="text-sm-left" />
                </Row>

                <Row>
                    {/* Editor */}
                    <Col>
                        <Card>
                            <CardHeader className="d-flex border-bottom active">
                                <ButtonGroup>
                                    <Button onClick={() => { setSelected(0); }} className={`mr-2 pr-4`}>View Students</Button>
                                    <Button onClick={() => { setSelected(1); }} className={`ml-2 pr-4`}>Add Student</Button>
                                </ButtonGroup>
                            </CardHeader>

                            {/* <PublishDraftGroup status={status} onClick={setStatus} /> */}
                            {/* <Button onClick={() => selected === 2 ? UpdateStudents() : AddStudent()} theme="primary" >Submit</Button> */}
                        </Card>
                    </Col>
                </Row>
                <Loader start={loader} />
            </Container>
        )
    }
    else {
        return (
            <Container fluid className="main-content-container px-4">
                <Loader start={loader} />
                {/* Page Header */}
                <Row noGutters className="page-header py-2">
                    <PageTitle sm="4" name="CBC Classes" subtitle="" className="text-sm-left" />
                </Row>

                {/* Default Light Table */}
                <Row>
                    <Col>
                        <Card small className="mb-4">
                            <CardHeader className="d-flex flex-wrap border-bottom">
                                <div className="d-flex flex-column flex-md-row justify-content-between w-100">
                                    <ButtonGroup className="w-md-50 mr-md-2 mb-2 mb-md-0">
                                        {/* <Button onClick={() => { setSelected(0); }} className={`mr-2 pr-4`}>View Students</Button> */}
                                        {/* <Button onClick={() => { setSelected(1); }} className={`ml-2 pr-4`}>Add Student</Button> */}
                                        <FormInput className="w-100" placeholder="Search Student" onChange={(e) => setSearchText(e.target.value)} />

                                    </ButtonGroup>
                                    {/* <div className="w-md-20">
                                        <CustomFormSelect batch={batch} setSelectedBatchId={setSelectedBatchId} selectedBatchId={selectedBatchId} />
                                    </div> */}
                                </div>
                            </CardHeader>
                            <CardBody className="p-0 pb-3 table-responsive-md">
                                <table className="table mb-0">
                                    <thead className="bg-light">
                                        <tr>
                                            <th scope="col" className="border-0 text-nowrap">
                                                #
                                            </th>
                                            <th scope="col" className="border-0 text-nowrap">
                                                Name
                                            </th>
                                            <th scope="col" className="border-0 text-nowrap">
                                                Actions
                                            </th>
                                            <th scope="col" className="border-0 text-nowrap">
                                                Last Pay
                                            </th>
                                            <th scope="col" className="border-0 text-nowrap">
                                                Last Pay Date
                                            </th>
                                            <th scope="col" className="border-0 text-nowrap">
                                                Dues
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            students && students.length ?
                                                students.map((data, index) =>
                                                    <tr key={index}>
                                                        <td className="text-nowrap">
                                                            {(page - 1) * 10 + index + 1}.
                                                        </td>
                                                        <td className="text-nowrap">
                                                            <img
                                                                src={data.imageUrl}
                                                                alt={data.name}
                                                                width="60"
                                                                onClick={() => onImageClick(data.imageUrl)}
                                                                height="40"
                                                            />
                                                            {data.name ? data.name.slice(0, 25) : ""}
                                                        </td>
                                                        <td className="text-nowrap">
                                                            <ButtonGroup>
                                                                <Button size="sm" onClick={() => openPaymenModal(data._id, false)} className="btn-outline-primary mr-1">Add Payment</Button>
                                                                <Button size="sm" onClick={() => openPaymenModal(data._id, true)} className="btn-outline-warning">View All Payment</Button>
                                                            </ButtonGroup>
                                                        </td>
                                                        <td className="text-nowrap">{data.lastPayment}</td>
                                                        <td className="text-nowrap">
                                                            {data.lastPaymentDate ? new Date(data.lastPaymentDate).toDateString() : "N/A"}
                                                        </td>
                                                        <td className="text-nowrap">{data.dues}</td>
                                                    </tr>
                                                ) : <tr></tr>
                                        }
                                    </tbody>
                                </table>
                            </CardBody>
                            <PopUpImage image={selectedImage || ""} showModal={showModal} setShowModal={setShowModal} />
                            <PaymentModel
                                imageUrl={selectedStudent.imageUrl}
                                name={selectedStudent.name}
                                studentId={selectedStudent._id}
                                setFee={setFee}
                                AddPayment={AddPayment}
                                paymentModal={paymentModal}
                                setPaymentModal={setPaymentModal}
                                fee={selectedStudent.fee}
                                dfee={selectedStudent.dfee || 0}
                                payment={payment}
                                setPayment={setPayment}
                                dues={selectedStudent.dues}
                                setNote={setNote}
                                note={note}
                                clearDue={clearDue}
                                setClearDue={setClearDue}
                            />
                            <ViewPaymentModal 
                                name={selectedStudent.name}
                                imageUrl={selectedStudent.imageUrl}
                                studentId={selectedStudent._id}
                                fee={selectedStudent.fee}
                                viewPaymentModal={viewPaymentModal}
                                setViewPaymentModal={setViewPaymentModal}
                                payments={selectedStudent.payments}
                                dfee={selectedStudent.dfee}
                            />
                        </Card>
                        <Row noGutters className="d-flex justify-content-between">
                            {totalCount && totalCount > 10 ? <Pagination
                                initialPage={page}
                                totalCount={totalCount}
                                onChangePage={PaginationHandler}
                            /> : <></>}
                            <PageTitle sm="4" name={totalCount || ""} subtitle="" className={`d-flex justify-content-${totalCount && totalCount > 10 ? "end" : "start"} text-sm-right`} />
                        </Row>
                    </Col>
                </Row>
                <Loader start={loader} />
            </Container>
        )
    }
};

export default FeeOversight;
