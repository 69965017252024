import { getFromLocalStorage } from "../lib/helper";

export default function () {

  const role = getFromLocalStorage("role");

  let adminPanel = []
  if (role === 'Admin') {
    adminPanel = [
      // {
      //   title: "Users",
      //   htmlBefore: '<i class="material-icons">vertical_split</i>',
      //   to: "/users",
      // },
      {
        title: "Batch",
        htmlBefore: '<i class="material-icons active">vertical_split</i>',
        to: "/batch",
      },
      {
        title: "Students",
        htmlBefore: '<i class="material-icons">vertical_split</i>',
        to: "/students",
      },
      {
        title: "Fee Oversight",
        htmlBefore: '<i class="material-icons">vertical_split</i>',
        to: "/fee",
      },
      {
        title: "Feeds",
        htmlBefore: '<i class="material-icons">vertical_split</i>',
        to: "/feeds",
      }
      // {
      //   title: "Notification",
      //   htmlBefore: '<i class="material-icons">vertical_split</i>',
      //   to: "/notification",
      // }
    ]
  }
  return [
  ].concat(adminPanel);
}
