import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, Button, ButtonGroup } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import { DELETE_REQUEST, GET_REQUEST, PATCH_REQUEST, POST_REQUEST } from "../lib/request";
import ActionButton from "../components/ActionButton";
import { toast } from "react-toastify";
import Loader from "../components/loader";
import { Constants } from "../flux";
import CreateStudents from "../components/students/CreateStudents";
import PublishDraftGroup from "../components/common/PublishedDraft";
import Pagination from "../components/index";
import PopUpImage from "../components/popupModal/imageModal";
import CustomFormSelect from "../components/common/Select";

const Students = () => {

    const [selected, setSelected] = React.useState(0)
    const [selectedDate, setSelectedDate] = React.useState(null);
    const [selectedScreen, setSelectedScreen] = React.useState(0);
    const [fee, setFee] = React.useState(0);
    const [dfee, setDFee] = React.useState(0);
    const [name, setName] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [students, setStudents] = React.useState([]);
    // const [content, setContent] = React.useState("");
    const [fName, setfName] = React.useState("");
    const [image, setImage] = React.useState(null);
    const [loader, setLoader] = React.useState(0);
    const [imageBinary, setImageBinary] = React.useState(null);
    const [batchId, setBatchId] = React.useState("");
    const [batch, setBatch] = React.useState([]);
    const [status, setStatus] = React.useState(1);
    const [studentId, setStudentId] = React.useState("");
    const [skip, setSkip] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [totalCount, setTotalResults] = React.useState(0);
    const [selectedImage, setSelectedImage] = React.useState("");
    const [showModal, setShowModal] = React.useState(false);
    const [selectedBatchId, setSelectedBatchId] = React.useState(null);

    const GET_STUDENTS = () => {
        setLoader(1)
        GET_REQUEST(`/student?batchId=${selectedBatchId === "All" || !selectedBatchId ? "" : selectedBatchId}&limit=10&skip=` + skip)
            .then((response) => {
                console.log('response.data', response.data);
                if (response && response.data && response.data.students) {
                    setStudents(response.data.students);
                    setTotalResults(response.data.totalResults);
                }
                if (response.status === 204) {
                    setStudents([]);
                    setTotalResults(0)
                }
                setLoader(0)
            })
            .catch((error) => {
                toast.error("Failed to Fetch Batches")
                console.log('Error in fetching data', error);
                setLoader(0)
            })
    }
    const GET_BATCH = () => {
        GET_REQUEST("/batch")
            .then((response) => {
                console.log('response', response.data);
                if (response && response.data && response.data.batch) {
                    setBatch(response.data.batch);
                }
                if (response.status === 204) {
                    setBatch([]);
                }
            })
            .catch((error) => {
                console.log('Error in fetching data', error);
            })
    }

    React.useEffect(() => {
        if (batch.length === 0) {
            GET_BATCH()
        }
    }, [selected])

    React.useEffect(() => {
        if (!selected) {
            GET_STUDENTS()
            setStudentId("");
            setName("");
            setPhone("");
            // setContent("");
            setfName("");
            setBatchId("");
            setImage("");
            setStatus(1);
        }
    }, [selected]);

    const AddStudent = () => {
        if (!batchId) {
            toast.error("Select a Batch First");
            return;
        }
        if (!name || !phone || !fName || !imageBinary || !selectedDate || !fee) {
            toast.error("name, phone, fName, doj, fee and image are manadatory!");
            return;
        }
        setLoader(1)
        const formData = new FormData();
        formData.append('image', imageBinary);
        POST_REQUEST("/upload", formData).then((response) => {
            if (response.status === 201) {
                if (response.data && response.data.path && response.data.path.filename) {
                    POST_REQUEST("/student", {
                        student: {
                            status: status,
                            "author": "CBC",
                            batchId,
                            name,
                            phone,
                            fName,
                            doj: selectedDate,
                            fee,
                            dfee,
                            urlToImage: response.data.path.filename,
                            imageUrl: response.data.imageUrl
                        }
                    })
                        .then((d) => {
                            toast.success("Students Created Successfully!");
                            GET_STUDENTS()
                            setSelected(0)
                        }).catch((e) => {
                            setSelected(1)
                        })
                }
            }
            setLoader(0);
        }).catch((error) => {
            console.error('Error While Uploading Image', error);
            setLoader(1)
            toast.error("Error While Uploading Image");
        })
    }

    const UpdateStudents = async () => {
        if (!name || !phone || selected !== 2 || !batchId || !fName || !selectedDate) {
            toast.error("name, phone, batchId, fName, Doj and image are manadatory field!")
            return;
        }
        setLoader(1);
        let studentData = { name, phone, fName, status, batchId, doj: selectedDate, fee, dfee }
        if (imageBinary) {
            const formData = new FormData();
            formData.append("image", imageBinary);
            await POST_REQUEST("/upload", formData).then((response) => {
                if (response.data && response.data.path && response.data.path.filename) {
                    studentData.urlToImage = response.data.path.filename;
                    studentData.imageUrl = response.data.imageUrl
                }
            })
                .catch((error) => {
                    toast.error("Failed to upload students image");
                    setLoader(0);
                    return;
                })
        }
        PATCH_REQUEST("/student", { studentId, student: studentData })
            .then((d) => {
                toast.success("Students Updated Successfully!")
                GET_STUDENTS()
                setSelected(0)
            }).catch((e) => {
                setSelected(1)
            })
        setLoader(0);
    }

    const deleteStudents = async (id) => {
        if (!students && !students.length) {
            return;
        }
        const newsdata = students.find((d) => d._id === id);
        if (!newsdata) {
            toast.error("No Such Students!");
            return;
        }
        setLoader(1)
        DELETE_REQUEST("/student", { studentId: id })
            .then((d) => {
                toast.success("Students Deleted Successfully!");
                setLoader(0);
                GET_STUDENTS();
            }).catch((e) => {
                setLoader(0);
                console.error('Error while deleting Students')
            })
    }


    const editStudents = async (id) => {
        if (!students && !students.length) {
            return;
        }
        const studentData = students.find((d) => d._id === id);
        if (!studentData) {
            toast.success("No Such Students Found!")
            return;
        }
        setStudentId(id);
        setName(studentData.name);
        setPhone(studentData.phone);
        setSelectedDate(studentData.doj);
        setFee(studentData.fee);
        setDFee(studentData.dfee);
        // setContent(studentData.content);
        setfName(studentData.fName);
        setBatchId(studentData.batchId);
        setStatus(studentData.status)
        setImage(Constants.IMAGE_URL + "/" + studentData.urlToImage);

        setSelected(2);
    }

    const onImageChange = async (event) => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            setImageBinary(img);
            setImage(URL.createObjectURL(img))
        }
    };

    const PaginationHandler = (data) => {
        setSkip((data - 1) * 10);
        setPage(data);
        // const skip = (data - 1) * 10
    }

    React.useEffect(() => {
        GET_STUDENTS()
    }, [skip, selectedBatchId])


    const onImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
        setShowModal(true);
    }

    if (selected) {
        return (
            <Container fluid className="main-content-container px-4 pb-4">
                {/* Page Header */}
                <Row noGutters className="page-header py-2">
                    <PageTitle sm="4" name="CBC Classes" subtitle="" className="text-sm-left" />
                </Row>

                <Row>
                    {/* Editor */}
                    <Col>
                        <Card>
                            <CardHeader className="d-flex border-bottom active">
                                <ButtonGroup>
                                    <Button onClick={() => {setSelected(0); setSelectedScreen(0)}} className={`mr-2 pr-4 ${selectedScreen === 0 ? 'btn-primary' : 'btn-outline-primary'}`}>View Students</Button>
                                    <Button onClick={() => {setSelected(1); setSelectedScreen(1)}} className={`ml-2 pr-4 ${selectedScreen === 1 ? 'btn-primary' : 'btn-outline-primary'}`}>Add Student</Button>
                                </ButtonGroup>
                            </CardHeader>
                            <CreateStudents
                                batch={batch}
                                batchId={batchId}
                                setBatchId={setBatchId}
                                name={name}
                                setName={setName}
                                phone={phone}
                                setPhone={setPhone}
                                selectedDate={selectedDate}
                                setSelectedDate={setSelectedDate}
                                fee={fee}
                                dfee={dfee}
                                setFee={setFee}
                                setDFee={setDFee}
                                header={selected === 2 ? "Update Student Data" : "Add Student"}
                                fName={fName}
                                setfName={setfName}
                                image={image}
                                onImageChange={onImageChange}
                            />
                            <PublishDraftGroup status={status} onClick={setStatus} />
                            <Button onClick={() => selected === 2 ? UpdateStudents() : AddStudent()} theme="primary" >Submit</Button>
                        </Card>
                    </Col>
                </Row>
                <Loader start={loader} />
            </Container>
        )
    }
    else {
        return (
            <Container fluid className="main-content-container px-4">
                {/* Page Header */}
                <Row noGutters className="page-header py-2">
                    <PageTitle sm="4" name="CBC Classes" subtitle="" className="text-sm-left" />
                </Row>

                {/* Default Light Table */}
                <Row>
                    <Col>
                        <Card small className="mb-4">
                            <CardHeader className="d-flex flex-wrap border-bottom">
                                <div className="d-flex flex-column flex-md-row justify-content-between w-100">
                                    <ButtonGroup className="w-md-50 mr-md-2 mb-2 mb-md-0">
                                        <Button onClick={() => {setSelected(0); setSelectedScreen(0)}} className={`mr-2 pr-4 ${selectedScreen === 0 ? 'btn-primary' : 'btn-outline-primary'}`}>View Students</Button>
                                        <Button onClick={() => {setSelected(1); setSelectedScreen(1)}} className={`ml-2 pr-4 ${selectedScreen === 1 ? 'btn-primary' : 'btn-outline-primary'}`}>Add Student</Button>
                                    </ButtonGroup>
                                    <div className="w-md-20">
                                        <CustomFormSelect batch={batch} setSelectedBatchId={setSelectedBatchId} selectedBatchId={selectedBatchId} />
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody className="p-0 pb-3 table-responsive-md">
                                <table className="table mb-0">
                                    <thead className="bg-light">
                                        <tr>
                                            <th scope="col" className="border-0">
                                                #
                                            </th>
                                            <th scope="col" className="border-0">
                                                Image
                                            </th>
                                            <th scope="col" className="border-0">
                                                Name
                                            </th>
                                            <th scope="col" className="border-0">
                                                Phone
                                            </th>
                                            <th scope="col" className="border-0">
                                                Father Name
                                            </th>
                                            <th scope="col" className="border-0">
                                                Joining Date
                                            </th>
                                            <th scope="col" className="border-0">
                                                Status
                                            </th>
                                            <th scope="col" className="border-0">
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            students && students.length ?
                                                students.map((data, index) =>
                                                    <tr key={index}>
                                                        <td>
                                                            {(page - 1) * 10 + index + 1}.
                                                        </td>
                                                        <td role="button">
                                                            <img
                                                                src={data.imageUrl}
                                                                alt={data.name}
                                                                width="60"
                                                                onClick={() => onImageClick(data.imageUrl)}
                                                                height="40"
                                                            />
                                                        </td>
                                                        <td>{data.name ? data.name.slice(0, 25) : ""}</td>
                                                        <td>{data.phone ? data.phone.slice(0, 25) : ""}</td>
                                                        <td>{data.fName ? data.fName.slice(0, 25) : ""}</td>
                                                        <td>
                                                            {new Date(data.doj).toDateString()}
                                                        </td>
                                                        <td>
                                                            <PublishDraftGroup status={data.status} disabled={true} />
                                                        </td>
                                                        <td>
                                                            <ActionButton
                                                                delete={deleteStudents}
                                                                edit={editStudents}
                                                                id={data._id}
                                                            />
                                                        </td>
                                                    </tr>
                                                ) : <tr></tr>
                                        }
                                    </tbody>
                                </table>
                            </CardBody>
                            <PopUpImage image={selectedImage || ""} showModal={showModal} setShowModal={setShowModal} />
                        </Card>
                        <Row noGutters className="d-flex justify-content-between">
                            {totalCount && totalCount > 10 ? <Pagination
                                initialPage={page}
                                totalCount={totalCount}
                                onChangePage={PaginationHandler}
                            /> : <></>}
                            <PageTitle sm="4" name={totalCount || ""} subtitle="" className={`d-flex justify-content-${totalCount && totalCount > 10 ? "end" : "start"} text-sm-right`} />
                        </Row>
                    </Col>
                </Row>
                <Loader start={loader} />
            </Container>
        )
    }
};

export default Students;
