import React from "react";
import {
    Modal,
    ModalBody,
    Card,
    CardBody,
    CardHeader,
    Container,
    Row,
    Col,
    CardFooter,
    Button,
} from "shards-react";
import "../../styling/styles/payment.css";

const ViewPaymentModal = (props) => {
    const getMonthName = (date) => {
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        return months[new Date(date).getMonth()];
    };

    return (
        <Modal open={props.viewPaymentModal} toggle={() => props.setViewPaymentModal(false)} size="lg">
            {/* <ModalHeader className="font-weight-bold">Payment History</ModalHeader> */}
            <ModalBody>
                <Container fluid>
                    <Row className="mb-4">
                        <Col md="12">
                            <Card className="student-info-card">
                                <CardBody className="d-flex align-items-center">
                                    <img
                                        src={props.imageUrl}
                                        alt={props.name}
                                        className="student-avatar"
                                    />
                                    <div className="student-details ml-3">
                                        <h6 className="mb-0 font-weight-bold">{props.name}</h6>
                                        <div className="d-flex">
                                            <div className="mr-3">
                                                <small className="text-muted font-italic">Monthly Fee</small>
                                                <p className="mb-0"><strong>₹{props.fee}</strong></p>
                                            </div>
                                            <div>
                                                <small className="text-muted font-italic">Monthly Discount</small>
                                                <p className="mb-0"><strong>₹{props.dfee || 0}</strong></p>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader className="font-weight-bold">Payment Timeline</CardHeader>
                                <CardBody>
                                    <div className="payment-timeline">
                                        {props.payments && props.payments.map((payment, index) => (
                                            <div key={index} className="payment-item">
                                                <div className="payment-status">
                                                    <span className="status-dot paid"></span>
                                                    <span className="status-line"></span>
                                                </div>
                                                <div className="payment-content">
                                                    <h6 className="m-0 font-weight-bold">{getMonthName(payment.paidOn)} {new Date(payment.paidOn).getFullYear()}</h6>
                                                    <p className="payment-amount paid p-0 m-0 font-weight-bold">
                                                        ₹{payment.payment} - Paid on {new Date(payment.paidOn).toLocaleDateString()}
                                                    </p>
                                                    {Number(payment.payment) > Number(payment.receivedPayment) && (
                                                        <small className="payment-note text-danger font-weight-bold">Actual Amount Received - ₹{payment.receivedPayment}</small>
                                                    )}
                                                    {payment.note && (
                                                        <small className="payment-note font-weight-bold">{payment.note}</small>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ModalBody>
            <CardFooter className="d-flex justify-content-end">
                <Button onClick={() => props.setViewPaymentModal(false)} theme="danger">Close</Button>
            </CardFooter>
        </Modal>
    )
}

export default ViewPaymentModal;