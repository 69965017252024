import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const Carousel = () => {
    const handleDragStart = (e) => e.preventDefault();

    const items = [
        <img className='rounded' style={{ width: '100%', maxHeight: '450px', objectFit: 'cover' }} src={require("../../images/cbc/1.webp")} onDragStart={handleDragStart} role="slider" />,
        <img className='rounded' style={{ width: '100%', maxHeight: '450px', objectFit: 'cover' }} src={require("../../images/cbc/2.webp")} onDragStart={handleDragStart} role="slider" />,
        <img className='rounded' style={{ width: '100%', maxHeight: '450px', objectFit: 'cover' }} src={require("../../images/cbc/3.webp")} onDragStart={handleDragStart} role="slider" />,
        <img className='rounded' style={{ width: '100%', maxHeight: '450px', objectFit: 'cover' }} src={require("../../images/cbc/4.webp")} onDragStart={handleDragStart} role="slider" />,
        <img className='rounded' style={{ width: '100%', maxHeight: '450px', objectFit: 'cover' }} src={require("../../images/cbc/5.webp")} onDragStart={handleDragStart} role="slider" />,
        <img className='rounded' style={{ width: '100%', maxHeight: '450px', objectFit: 'cover' }} src={require("../../images/cbc/6.webp")} onDragStart={handleDragStart} role="slider" />,
        <img className='rounded' style={{ width: '100%', maxHeight: '450px', objectFit: 'cover' }} src={require("../../images/cbc/7.webp")} onDragStart={handleDragStart} role="slider" />,
    ];

    return (
        <div id="home" className='p-4'>
            <AliceCarousel
                responsive={true}
                activeIndex={0}
                autoPlay={true}
                infinite={true}
                animationDuration={1000}
                autoPlayInterval={1000}
                disableButtonsControls={true}
                disableDotsControls={true}
                mouseTracking
                items={items}
                renderNextButton={{ isDisabled: false }}
            />
        </div>
    );
};

export default Carousel;
