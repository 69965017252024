import React from "react";
import {
  Form,
  // InputGroup,
  // InputGroupAddon,
  // InputGroupText,
  // FormInput
} from "shards-react";

export default () => (
  <Form className="main-navbar__search w-100 d-none d-md-flex d-lg-flex">
    {/* <InputGroup seamless className="ml-3">
      <InputGroupAddon type="prepend">
        <InputGroupText>
          <i className="material-icons">search</i>
        </InputGroupText>
      </InputGroupAddon>
      <FormInput
        className="navbar-search"
        placeholder="Search for something..."
      />
    </InputGroup> */ }
      <h4 className="w-100 text-uppercase font-weight-bold text-center mt-3" >CBC Classes</h4>
  </Form>
);
