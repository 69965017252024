import React from "react";
import {
    Card,
    CardImg,
    CardBody,
    CardFooter,
    Button,
    FormInput,
    Modal,
    ModalBody,
    Container,
    Row,
    Col,
    FormCheckbox
} from "shards-react";

const PaymentModel = (props) => {
    return (
        <Modal open={props.paymentModal} toggle={() => props.setPaymentModal(false)} size="lg">
            {/* <ModalHeader>Submitting {props.name} Payment</ModalHeader> */}
            <ModalBody>
                <Container fluid>
                    <Row>
                        <Col sm="4">
                            <CardImg
                                src={props.imageUrl}
                                style={{ width: '100%', height: 'auto', maxWidth: '150px' }}
                                className="rounded-circle mx-auto d-block mb-3"
                            />
                            <div className="text-center">
                                <h5 className="font-weight-bold">{props.name}</h5>
                                <p className="text-muted small mb-0 font-weight-bold">Monthly Fee: <mark>{props.fee}</mark></p>
                                <p className="text-muted small font-weight-bold">Monthly Discount: <mark>{props.dfee}</mark></p>
                            </div>
                        </Col>
                        <Col sm="8">
                            <Card>
                                <CardBody>
                                    <p className="text-muted small mb-1 font-italic font-weight-bold">Only add payment you have received. The system will calculate the dues automatically.</p>
                                    <p className="text-muted small mb-1 font-weight-bold">Total Balance <mark>{props.dues}</mark> </p>
                                    {/* <FormCheckbox
                                        className="font-italic font-weight-bold"
                                        checked={props.clearDue}
                                        onChange={e => props.setClearDue(!props.clearDue)}
                                    >
                                        Clear all the dues.
                                    </FormCheckbox> */}
                                    <FormCheckbox
                                        toggle
                                        small
                                        checked={props.clearDue}
                                        onChange={e => props.setClearDue(!props.clearDue)}>
                                        🚀 Clear Dues
                                    </FormCheckbox>
                                    <p className="text-danger small mb-1 font-weight-bold">{props.clearDue && props.payment && props.dues && props.payment != props.dues && props.payment < props.dues ? `Clearing ${props.dues} dues on payment of ${props.payment}` : ''}</p>
                                    <FormInput
                                        required={true}
                                        value={props.payment}
                                        placeholder="Actual Payment Received"
                                        onChange={(event) => props.setPayment(event.target.value)}
                                        className="mb-3"
                                        type="number"
                                    />
                                    <FormInput
                                        placeholder="Small Note"
                                        onChange={(event) => props.setNote(event.target.value)}
                                        className="mb-3"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ModalBody>
            <CardFooter className="d-flex justify-content-between">
                <Button onClick={() => props.setPaymentModal(false)} theme="danger">Cancel</Button>
                <Button onClick={() => { props.AddPayment(props.studentId) }} theme="primary">Add Payment</Button>
            </CardFooter>
        </Modal>
    )
}

export default PaymentModel;